<template>
  <div class="d-flex flex-column flex-root">
    <Loader v-bind:logo="loaderLogo" spinner-class="spinner-success"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_AUTH, VERIFY_AUTH} from '@/store/core/auth.module';
import ApiService from '@/core/services/api.service';
import Loader from '@/core/content/Loader.vue';
import LocalStorageService from '@/core/services/local-storage.service';
import {ADD_BODY_CLASSNAME} from '@/store/core/htmlclass.module';
import HtmlClass from '@/core/services/htmlclass.service';

export default {
  components: {
    Loader,
    ApiService,
    LocalStorageService
  },
  data: () => ({
    accessToken: null,
    refreshToken: null,
    redirectTo: '/dashboard'
  }),
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    this.accessToken = this.$route.query.access_token;
    this.refreshToken = this.$route.query.refresh_token;
    if (this.$route.query.redirect_to) {
      this.redirectTo = this.$route.query.redirect_to;
    }

    if (!this.accessToken || !this.refreshToken) return false;

    LocalStorageService.setAccessToken(this.accessToken);
    LocalStorageService.setRefreshToken(this.refreshToken);

    this.$store.dispatch(VERIFY_AUTH);
    this.$store.dispatch('profileStore/GET_PROFILE');
  },
  computed: {
    ...mapGetters({
      layoutConfig: 'layoutConfig',
      user: 'profileStore/PROFILE'
    }),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    }
  },
  watch: {
    user(data) {
      this.$store.commit(SET_AUTH, data);
      this.$router.push(this.redirectTo);
    }
  }
}
</script>